<template>
  <div class="transmit_sub_container">
    <div class="transmittal_slip_filter adj_sur">
      <div class="label_element_cover">
      <label>Enter</label>
      <input class="input_300"
        type="text"
        placeholder="Search by Receipt No/Customer Ref/AWB No"
        v-model="receiptNo"
        @keyup.enter="searchReceiptNo"
        @keydown="nameKeydown($event)"
      />
      </div>
      <div class="label_element_cover">
      <label>Filter By Date</label>
      <flat-pickr
        class="date_for_pos_fixed"
        v-model="daterange"
        :config="configDate"
        placeholder="Date"
        name="birthdate"
        @on-close="dateChanged"
      ></flat-pickr>
      </div>
      <div class="label_element_cover">
      <label
        v-if="auth().user_type != 'super_admin' && auth().user_type != 'customer_service_user'"
      >In / Out</label>
      <select
        v-model="inoutfilter"
        @change="filterInOut"
        v-if="auth().user_type != 'super_admin' && auth().user_type != 'customer_service_user'"
      >
        <option value disabled="disabled" selected="selected">Select status</option>
        <option value="all">All</option>
        <option value="Incoming">Incoming</option>
        <option value="Outgoing">Outgoing</option>
      </select>
      </div>
      <div class="label_element_cover">
      <label>Filter By Status</label>
      <select v-model="status" @change="filterStatus">
        <option value disabled="disabled" selected="selected">Select status</option>
        <option value="all">All</option>
        <option value="Booked">Booked</option>
        <option value="Cancelled">Cancelled</option>
        <option value="Picked Up">Picked Up</option>
        <option value="On-Ride">On-Ride</option>
        <option value="Arrived">Arrived</option>
        <option value="Dispatched">Dispatched</option>
      </select>
      </div>
      <div class="label_element_cover">
      <label>Type of Jobs</label>
      <select v-model="typeOfJob" @change="filterCourierType">
        <option value disabled="disabled" selected="selected">Select job type</option>
        <option value="Transport">Transport</option>
      </select>
      </div>
    </div>
    <section>
      <div class="two_tabs">
        <a class="tab_buttons active">TRANSPORT</a>
      </div>

      <div class="jobs_table">
        <table>
          <tr>
            <th>DATE & TIME</th>
            <th>SERVICE</th>
            <th>TRANSPORT COMPANY</th>
            <th>REQUESTER</th>
            <th>PAX</th>
            <th>PICKUP ADDRESS</th>
            <th>DROP ADDRESS</th>
            <th>INSTRUCTION</th>
            <th>STATUS</th>
          </tr>
          <tbody v-if="!loading">
            <tr v-for="(data,i) in courier" :key="i">
              <td>
                <span class="label">{{data.booking_type}}</span>
              <!--  <br />
                {{data.status_date}}
                <br />
                {{data.status_time}}-->
                 <!-- <p class="text-center">{{data.created_at}}</p>  -->
                <p class="text-center" v-if="data.booked_courier_status != null">{{data.booked_courier_status.date}}</p>
                <br/>
                <p class="text-center" v-if="data.booked_courier_status != null">{{data.booked_courier_status.time}}</p>
              </td>
              <td>
                <span class="label">{{data.delivery_service}}</span>
                <br />
                <p>Vehicle Model: {{data.transport.vehicle_model}}</p>
                <p>Registration Number : {{data.transport.vehicle_reg_number}}</p>
              </td>
              <td>
                <p>Name: {{data.dispatch_company}}</p>
                <p>Driver Name: {{data.transport.driver_name}}</p>
                <p>Approver Name: {{data.approver_name}}</p>
                <p>Approver Designation: {{data.approver_designation}}</p>
              </td>
              <td>
                <p>
                  Booking ID:
                  <b>{{data.booking_prefix}}{{data.booking_id}}</b>
                </p>
                <p>Name: {{data.pickup_name}}</p>
                <p>Mobile: {{data.pickup_mobile}}</p>
                <p>{{data.pickup_date}} , {{data.pickup_time}}</p>
                <p>Branch Name: {{data.pickup_branch}}</p>
                <p>Cost Center: {{data.cost_center_name}}</p>
                <span class="priority-chip" v-if="data.has_priority == 1 ">Priority: Urgent</span>
                <span class="priority-chip1" v-if="data.has_emergency == 1 ">Emergency</span>
              </td>
              <td>{{data.transport.no_of_passengers}}</td>
              <td>
                <!-- <p>From:</p> -->
                <p>{{data.pickup_name}}</p>
                <p>{{data.pickup_address_1 ? data.pickup_address_1 + ', ':''}}{{data.pickup_address_2 ? data.pickup_address_2 + ', ':""}}{{data.pickup_city ? data.pickup_city:''}}</p>
              </td>
              <td>
                <!-- <p>To:</p> -->

                <p>{{data.delivery_address_1 ? data.delivery_address_1 + ', ':''}}{{data.delivery_address_2 ? data.delivery_address_2+', ':''}}{{data.delivery_city ? data.delivery_city: ''}}</p>
              </td>
              <td>
                <p>{{data.pickup_instructions || 'NA'}}</p>
              </td>
              <td>
                <span
                  class="label green"
                  v-if="auth().user_type == 'user' || auth().user_type == 'hub_user' || auth().user_type == 'hub_admin'"
                >{{data.mail_type}}</span>
                <div class="icons_wrapper">
                  <div class="view_optons_wrapper" v-if="data.show_view_popup">
                    <a class="view_option_button" @click="searchConsignment(data)">View Receipt</a>
                    <a class="view_option_button" @click="viewSummary(data)">View Summary</a>
                  </div>
                  <a @click="data.show_view_popup = !data.show_view_popup">
                    <i class="material-icons blue" title="View">remove_red_eye</i>
                  </a>
                  <a>
                    <router-link
                      title="Edit"
                      :to="{ name: 'QuickTransportBooking', params: { id: data.id , type: 'edit'}}"
                      v-if="data.status != 'Dispatched' "
                    >
                      <i class="material-icons red" title="Edit">mode_edit</i>
                    </router-link>
                    <i class="material-icons grey" title="Could not be edited" v-else>mode_edit</i>
                  </a>
                 
                  <!-- <a>
                    <i class="material-icons green" title="Update">update</i>
                  </a>-->

                  <a  v-if="data.status != 'Dispatched' && auth().user_type != 'user'">
                    <router-link
                      title="Update"
                      :to="{ name: 'QuickTransportBooking', params: { id: data.id , type: 'update'}}"
                    >
                      <i class="material-icons green" title="Update">update</i>
                    </router-link>
                    <!-- <i class="material-icons grey" title="Could not be updated" v-else>update</i> -->
                  </a>
                    <a v-else>
                    <i class="material-icons grey" title="Could not be updated">update</i>
                  </a>
              

              
                  <a v-if="data.status != 'Dispatched'" @click="deleteBooking(data.id)">
                    <i class="material-icons red" title="Delete">delete</i>
                  </a>
                  <a v-else>
                    <i class="material-icons grey" title="Could not be deleted">delete</i>
                  </a>
                  <a v-if="data.courier_comments.length > 0" @click="showpopCommentDiary = true">
                    <i
                      class="material-icons green"
                      @click="addcomment(data)"
                      title="Type your Comment here"
                    >comment</i>
                  </a>
                  <a v-else @click="showpopCommentDiary = true">
                    <i
                      class="material-icons orange"
                      @click="addcomment(data)"
                      title="Type your Comment here"
                    >comment</i>
                  </a>
                </div>
                <a class="bt bt_black">{{data.status}}</a>
                <a
                  class="bt bt_gold"
                  v-if="auth().user_type == 'hub_admin' || auth().user_type == 'super_admin' && data.status != 'Booking uploaded' "
                  @click="updatestatus(data)"
                >Update Status</a>
              </td>
            </tr>
          </tbody>
        </table>
        <p v-if="!loading && courier.length == 0">No data found</p>
      </div>
      <loader v-if="loading" />
      <paginate
        v-if="pages > 0"
        v-show="!loading"
        v-model="current_page"
        :page-count="pages"
        active-class="paginate-active"
        :click-handler="fetchCouriers"
        prev-link-class="paginate-previous"
        next-link-class="paginate-next"
        :prev-text="'Prev'"
        :next-text="'Next'"
        :container-class="'pagination'"
        :no-li-surround="true"
      ></paginate>
    </section>

    <div class="black-overlay" v-if="popupdatestatus_booking_id">
      <div class="box-modal smallPops">
        <div class="body">
          <popUpdateStatusTransport
            @closeModal="popupdatestatus_booking_id = '',fetchCouriers(current_page);"
            :booking_id="popupdatestatus_booking_id"
            :airway_bill="popupdatestatus_airway_bill"
            :booking_prefix="popupdatestatus_booking_prefix"
            v-if="popupdatestatus_booking_id"
          />
        </div>
      </div>
    </div>

    <div class="black-overlay" v-if="popcommentdiary_id">
      <div class="box-modal smallPops">
        <div class="body">
          <popCommentDiary
            @closeModal="popcommentdiary_id = ''"
            :id="popcommentdiary_id"
            v-if="popcommentdiary_id"
          />
        </div>
      </div>
    </div>

    <div class="black-overlay" v-show="viewconsignmentpopup_booking_id">
      <div class="box-modal printPops">
        <div class="body">
          <ViewConsignmentJourney
            @closeModal="closeConsignment"
            :booking_id="viewconsignmentpopup_booking_id"
            :booking_prefix="viewconsignmentpopup_booking_prefix"
            v-if="viewconsignmentpopup_booking_id"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import popUpdateStatusTransport from "./popups/popUpdateStatusTransport.vue";
import popCommentDiary from "./popups/popCommentDiary.vue";
import ViewConsignmentJourney from "./ViewConsignmentJourney.vue";
import moment from "moment";

export default {
  name: "ViewPendingTransport",
  components: {
    flatPickr,
    popUpdateStatusTransport,
    popCommentDiary,
    ViewConsignmentJourney
  },
  data() {
    return {
      receiptNo: "",
      daterange: moment(new Date()).format('YYYY-MM-DD'),
      status: "",
      inoutfilter: "",
      typeOfJob: "Transport",
      enableTime: true,
      configDate: {
        mode: "range",
        format: "d-m-Y",
        altFormat: "d-m-Y",
        altInput: true
      },
      viewBoxShow: false,
      showpopUpdateStatus: false,
      showpopCommentDiary: false,
      showViewConsignment: false,
      courier: [],
      pages: 0,
      current_page: 0,
      post_data: {
        from:moment(new Date()).format('YYYY-MM-DD'),
        to: moment(new Date()).format('YYYY-MM-DD'),
        value: "",
        statusfilter: "",
        jobType: "",
        inout: "",
        view_pending: 2
      },
      viewconsignmentpopup_booking_id: "",
      viewconsignmentpopup_booking_prefix: "",
      popupdatestatus_booking_id: "",
      popupdatestatus_airway_bill:"",
      popupdatestatus_booking_prefix: "",
      popcommentdiary_id: "",
      loading: true
    };
  },
  created() {
    this.$emit("breadCrumbData", ["View Transport Jobs"]);
    if (localStorage.viewPending) {
      let data = JSON.parse(localStorage.viewPending);
      if (data.jobType) {
        this.typeOfJob = data.jobType;
        this.post_data.jobType = data.jobType;
      }
      localStorage.removeItem("viewPending");
    }
    this.fetchCouriers();
  },
  methods: {
      nameKeydown(e) {
      if (/^\W$/.test(e.key)) {
        e.preventDefault();
      }
    },
    dateChanged(daterange) {
      this.post_data.from = moment(daterange[0]).format("YYYY-MM-DD");
      this.post_data.to = moment(daterange[1]).format("YYYY-MM-DD");
      this.fetchCouriers();
    },
    searchReceiptNo() {
      this.post_data.value = this.receiptNo;
      this.fetchCouriers();
    },
    filterInOut() {
      this.post_data.inout = this.inoutfilter;
      this.fetchCouriers();
    },
    filterStatus() {
      this.post_data.statusfilter = this.status;
      this.fetchCouriers();
    },
    filterCourierType() {
      this.post_data.jobType = this.typeOfJob;
      this.fetchCouriers();
    },
    fetchCouriers(page = 1) {
      console.log(this.post_data);
      this.loading = true;
      this.current_page = page;
      this.axios
        .post("/api/courier/" + page + "/listingAndFilter", this.post_data)
        .then(response => {
          console.log(response);
          this.courier = response.data.couriers.list.map(element => {
            element.show_view_popup = false;
            return element;
          });
          this.pages = response.data.couriers.pages;
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    closeConsignment() {
      this.viewconsignmentpopup_booking_id = "";
      this.courier = this.courier.map(element => {
        element.show_view_popup = false;
        return element;
      });
    },
    searchConsignment(booking) {
      this.viewconsignmentpopup_booking_prefix = booking.booking_prefix;
      this.viewconsignmentpopup_booking_id = booking.booking_id;
    },
    viewSummary(data) {
      localStorage.view_summary = JSON.stringify({
        booking_id: data.booking_prefix + data.booking_id
      });
      this.$router.push("/pages/ViewProcessed");
    },
    updatestatus(booking) {
      this.popupdatestatus_booking_prefix = booking.booking_prefix;
      this.popupdatestatus_booking_id = booking.booking_id;
      this.popupdatestatus_airway_bill = booking.airway_bill;
    },
    addcomment(id) {
      console.log(id);
      this.popcommentdiary_id = id.id;
      console.log(this.popcommentdiary_id);
    },
    deleteBooking(id) {
      if (confirm("Are you sure you want to delete this booking?")) {
        this.axios
          .delete("api/courier/" + id)
          .then(response => {
						if (response.data.success) {
							this.toast.success(response.data.message);
							
						} else {
							this.toast.error(response.data.message);
						}
						this.fetchCouriers();
					})
          .catch(error => {
            console.log(error);
          });
      }
    }
  }
};
</script>
<style lang="scss">
$theme-color: #06A5ED;
.jobs_table {
  border-top: 5px solid $theme-color;
  table {
    width: 100%;
    border-collapse: collapse;
    border-left: 1px solid #ccc;
    font-size: 14px;
    tr {
      th {
        background: #004a7c;
        padding: 20px 10px;
        color: #ccc;
        font-weight: 700;
        border: 1px solid #222;
        border-left: 0;
        border-top: 0;
        text-align: left;
      }
      td {
        background: #eee;
        padding: 20px 10px;
        color: #333;
        font-weight: 400;
        border: 1px solid #ccc;
        border-left: 0;
        border-top: 0;
        text-align: left;
        vertical-align: top;
        p {
          padding: 5px 0;
          b {
            font-weight: 700;
          }
        }
        span.label {
          display: inline-block;
          font-weight: 700;
          background: #000;
          color: #fff;
          border-radius: 4px;
          padding: 5px;
          &.green {
            background: #46aa42;
          }
        }
        .icons_wrapper {
          padding: 10px 0;
          display: flex;
          justify-content: space-between;
          position: relative;

          a {
            display: inline-block;
            width: 24px;
            height: 24px;
            .material-icons {
              font-size: 18px;
              position: relative;
              top: 0;
              margin: 0;
              font-weight: normal;
              &.blue {
                color: rgb(45, 91, 241);
              }
              &.red {
                color: rgb(241, 74, 45);
              }
              &.green {
                color: #46aa42;
              }
              &.orange {
                color: orange;
              }
            }
          }
        }
        .bt {
          width: 120px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          border-radius: 4px;
          display: block;
          margin: 5px 0;
        }
        .bt_black {
          background: #063657;
          color: #fff;
        }
        .bt_gold {
          background: $theme-color;
          color: #000;
        }
      }
    }
  }
}
.priority-chip {
  background-color: #f44336;
  color: white;
  padding: 5%;
  border-radius: 3px;
  font-size: 12px;
}
.priority-chip1 {
  background-color: #f70202;
  color: white;
  padding: 5%;
  border-radius: 3px;
  font-size: 12px;
}
.two_tabs {
  padding: 40px 10px 0 10px;
  display: flex;
  justify-content: space-around;
  .tab_buttons {
    display: block;
    width: 40%;
    height: 50px;
    text-align: center;
    line-height: 50px;
    background: #ececec;
    color: #333;
    border: 1px solid #ddd;
    span.label {
      display: none;
      background: $theme-color;
      color: #000;
      padding: 5px 10px;
      border-radius: 4px;
    }
  }
  .tab_buttons.active {
    background: #004a7c;
    color: #fff;
  }
}
.view_optons_wrapper {
  width: 240px;
  height: auto;
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
  position: absolute;
  left: -250px;
  top: 10px;
  padding: 10px;
  a.view_option_button {
    width: 100% !important;
    height: 40px !important;
    background: $theme-color;
    color: #000;
    display: block;
    margin-bottom: 10px;
    text-align: center;
    line-height: 40px;
  }
  a.view_option_button:last-child {
    background: #aaa;
    color: #000;

    margin-bottom: 0;
  }
}
</style>
<style scoped>
@media screen {
  .black-overlay .box-modal.printPops {
    width: 730px;
  }
}
@media print {
  .black-overlay .box-modal.printPops {
    width: 730px !important;
  }
}
</style>